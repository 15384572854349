import { FC } from 'react';
import IconProps from './IconProps';

const RefreshIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 4C5 3.44772 4.55228 3 4 3C3.44772 3 3 3.44772 3 4H5ZM4 9H3C3 9.55228 3.44772 10 4 10V9ZM4.58152 10C5.1338 10 5.58152 9.55228 5.58152 9C5.58152 8.44772 5.1338 8 4.58152 8V10ZM18.946 11.1237C19.0144 11.6718 19.5141 12.0607 20.0621 11.9923C20.6101 11.924 20.999 11.4243 20.9307 10.8763L18.946 11.1237ZM3.65484 8.62479C3.44762 9.13672 3.69463 9.71971 4.20657 9.92694C4.7185 10.1342 5.3015 9.88715 5.50872 9.37521L3.65484 8.62479ZM4.58178 8.00001C4.0295 8.00001 3.58178 8.44772 3.58178 9.00001C3.58178 9.55229 4.0295 10 4.58178 10V8.00001ZM9.00026 10C9.55255 10 10.0003 9.55229 10.0003 9.00001C10.0003 8.44772 9.55255 8.00001 9.00026 8.00001V10ZM19 20C19 20.5523 19.4477 21 20 21C20.5523 21 21 20.5523 21 20H19ZM20 15H21C21 14.4477 20.5523 14 20 14V15ZM19.4185 14C18.8662 14 18.4185 14.4477 18.4185 15C18.4185 15.5523 18.8662 16 19.4185 16V14ZM20.3456 15.3752C20.5528 14.8633 20.3058 14.2803 19.7939 14.0731C19.282 13.8658 18.699 14.1129 18.4917 14.6248L20.3456 15.3752ZM5.05436 12.8763C4.98602 12.3282 4.48635 11.9394 3.93831 12.0077C3.39027 12.076 3.00139 12.5757 3.06973 13.1238L5.05436 12.8763ZM19.4185 16C19.9708 16 20.4185 15.5523 20.4185 15C20.4185 14.4477 19.9708 14 19.4185 14V16ZM15 14C14.4477 14 14 14.4477 14 15C14 15.5523 14.4477 16 15 16V14ZM3 4V9H5V4H3ZM4 10H4.58152V8H4V10ZM20.9307 10.8763C20.3769 6.43564 16.5906 3 12.0003 3V5C15.5692 5 18.5156 7.67174 18.946 11.1237L20.9307 10.8763ZM12.0003 3C8.22164 3 4.98921 5.32832 3.65484 8.62479L5.50872 9.37521C6.54789 6.80802 9.06413 5 12.0003 5V3ZM4.58178 10H9.00026V8.00001H4.58178V10ZM21 20V15H19V20H21ZM20 14H19.4185V16H20V14ZM18.4917 14.6248C17.4525 17.192 14.9363 19 12.0002 19V21C15.7788 21 19.0112 18.6717 20.3456 15.3752L18.4917 14.6248ZM12.0002 19C8.43125 19 5.48482 16.3283 5.05436 12.8763L3.06973 13.1238C3.62347 17.5644 7.40983 21 12.0002 21V19ZM19.4185 14H15V16H19.4185V14Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default RefreshIcon;
